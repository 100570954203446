import { wagmiConfig } from '@/providers/rainbow';
import { WalletButton } from '@rainbow-me/rainbowkit';
import { OptionsProps } from '..';
import { useAccountEffect } from 'wagmi';
import { RainbowKitDetails } from 'node_modules/@rainbow-me/rainbowkit/dist/wallets/Wallet';
import { useMemo } from 'react';

export function EVMOptions({ onOpenChange }: OptionsProps) {
  useAccountEffect({
    onConnect: () => {
      onOpenChange(false);
    },
  });

  const wallets = useMemo(() => {
    return wagmiConfig?.connectors.filter(el => 'rkDetails' in el);
  }, []);

  const sortedWallets = [...wallets.slice(0, wallets.length - 1)];

  // Extract Binance and OKX
  const binance = sortedWallets.find(
    w => (w?.rkDetails as RainbowKitDetails)?.id === 'wallet.binance.com'
  );
  const okx = sortedWallets.find(
    w => (w?.rkDetails as RainbowKitDetails)?.id === 'okx'
  );

  // Remove Binance and OKX from the list
  const otherWallets = sortedWallets.filter(
    w =>
      (w?.rkDetails as RainbowKitDetails)?.id !== 'wallet.binance.com' &&
      (w?.rkDetails as RainbowKitDetails)?.id !== 'okx'
  );

  // Build final list with Binance at index 1 and OKX at index 2
  const finalWallets = [...otherWallets];

  if (binance) finalWallets.splice(1, 0, binance);
  if (okx) finalWallets.splice(2, 0, okx);

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">EVM wallets</div>
      <div className="connect-wallet--group-grid">
        {finalWallets.map((item, i) => (
          <WalletButton.Custom
            key={i}
            wallet={(item?.rkDetails as RainbowKitDetails)?.id}
          >
            {({ connect, connector }) => {
              return (
                <button
                  onClick={
                    connector?.id === 'walletConnect'
                      ? connector?.showWalletConnectModal
                      : connect
                  }
                  className="connect-wallet--group-item"
                >
                  <img
                    src={require(
                      `@/Assets/images/wallets/${(item?.rkDetails as RainbowKitDetails)?.id}.png`
                    )}
                    style={{ height: '24px', width: '24px' }}
                    alt=""
                  />
                  {(item?.rkDetails as RainbowKitDetails).name}
                </button>
              );
            }}
          </WalletButton.Custom>
        ))}
      </div>
    </div>
  );
}

export const BITCOIN_ICONS: Record<string, string> = {
  unisat: 'unisat',
  'OKX Wallet': 'okx',
  bitget: 'bitget',
  binancew3w: 'binance',
  xverse: 'xverse',
  leather: 'leather',
  metamask: 'metaMask',
};

export const getBitcoinWalletIcon = (wallet?: string) => {
  return wallet && BITCOIN_ICONS[wallet]
    ? require(`@/Assets/images/wallets/${BITCOIN_ICONS[wallet]}.png`)
    : require('@/Assets/images/wallets/bitcoin.png');
};

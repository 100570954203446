import { Faucet } from '@/Assets/Icons/Faucet';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { DappsIcon } from '@/Assets/Icons/dapps';
import retroIcon from '@/Assets/Icons/retroIcon.svg';
import { ROUTES } from '@/constants/routes.constants';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu';
import {
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import './styles.css';
import { useState } from 'react';
import { RetroBridgeLogoGradient, RetroGPTIcon } from '@/Assets/Icons/icons';

interface HeaderProps {
  variant?: 'default' | 'light';
}

export function Header({ variant = 'default' }: HeaderProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="header">
      <Link to={ROUTES.LANDING_PAGE}>
        <img
          src={retroIcon}
          alt="logo"
          style={{ width: '170px', height: '40px' }}
        />
      </Link>

      <nav className="header-nav">
        <Link to={ROUTES.DAPP_EXPLORER_PAGE} className="header-nav-item">
          {variant === 'default' && <DappsIcon />}
          {variant === 'light' && <DappsIcon color="#fff" />}
          Dapps Explorer
        </Link>
        <Link to={ROUTES.TESTNET_SEND_PAGE} className="header-nav-item">
          {variant === 'default' && <Faucet />}
          {variant === 'light' && <Faucet color="#fff" />}
          Testnets & Faucet
        </Link>
        <Link to={ROUTES.QUESTS_PAGE} className="header-nav-item">
          <RetroBridgerPointsIcon width={18} height={13} />
          Retro Points
        </Link>

        <Root open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild onMouseEnter={() => setIsOpen(true)}>
            <button className="headerBtn header-nav-item fw-bold">
              Launch
            </button>
          </PopoverTrigger>

          <PopoverPortal>
            <PopoverContent
              hideWhenDetached
              align="end"
              sideOffset={17}
              avoidCollisions={false}
              autoFocus={false}
              onOpenAutoFocus={e => e.preventDefault()}
              onMouseLeave={() => setIsOpen(false)}
              asChild
            >
              <div className="header-popover-content">
                <Link
                  to={ROUTES.SEND_PAGE}
                  className="headerBtn headerBtnPopover header-nav-item fw-bold"
                >
                  <RetroBridgeLogoGradient />
                  Bridge
                </Link>
                <div
                  //to={ROUTES.SEND_PAGE}
                  className="headerBtn headerBtnPopover header-nav-item fw-bold"
                >
                  <RetroGPTIcon />
                  RetroGPT
                </div>
              </div>
            </PopoverContent>
          </PopoverPortal>
        </Root>
      </nav>
      <div className="d-flex align-items-center ms-auto d-lg-none">
        <Link
          to={ROUTES.SEND_PAGE}
          className="btnGradient header-nav-item--btn"
        >
          App
        </Link>
        <MobileMenu />
      </div>
    </header>
  );
}

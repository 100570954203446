import { useAccount } from 'wagmi';
import metamaskIcon from '@/Assets/images/wallets/metaMask.png';
import { RainbowKitDetails } from 'node_modules/@rainbow-me/rainbowkit/dist/wallets/Wallet';

export const useConnectedIcon = () => {
  const { connector } = useAccount();
  const name = (connector?.rkDetails as RainbowKitDetails)?.name;

  const icons: Record<string, string> = {
    'Trust Wallet': 'trust',
    MetaMask: 'metaMask',
    Rainbow: 'rainbow',
    Phantom: 'phantom',
    'Rabby Wallet': 'rabby',
    'Coinbase Wallet': 'coinbase',
    imToken: 'imToken',
    'SafePal Wallet': 'safepal',
    'Coin98 Wallet': 'coin98',
    TokenPocket: 'tokenPocket',
    Bitget: 'bitget',
    'OKX Wallet': 'okx',
    Zerion: 'zerion',
    WalletConnect: 'walletConnect',
    foxwallet: 'foxwallet',
    'Binance Wallet': 'binance',
  };

  if (name) {
    return connector && icons[name]
      ? require(`@/Assets/images/wallets/${icons[name]}.png`)
      : metamaskIcon;
  } else {
    return connector && icons[connector.name]
      ? require(`@/Assets/images/wallets/${icons[connector.name]}.png`)
      : metamaskIcon;
  }
};

import { isOneDomain as IS_ONE_DOMAIN } from '@/Config';
import { STAGES, stage } from '../Config/ApiConfig';

export const DAPP_EXPLORER_PAGE = '/dapp-explorer';
export const EXPLORER_PAGE = '/explorer';
export const PROGRESS_PAGE = '/progress';
export const ADVANCED_PROGRESS_PAGE = '/progress/advanced';
export const HOME_PAGE = '/';
export const SEND_PAGE = '/send';

export const QUESTS_PAGE = '/quests';
export const QUESTS_PAGE_PHASES = '/quests/phases';
export const QUESTS_PAGE_ADVENTURES = '/quests/adventures';
export const QUESTS_PAGE_ACTIVITIES = '/quests/activities';
export const QUESTS_PAGE_MARKET = '/quests/market';

export const DEV_API_PAGE = '/for-developers';
export const PRIVACY_POLICY_PAGE = '/terms-conditions';
export const AUDIT_PAGE = window.location.origin + '/audit.pdf';
export const MONITORING_AUDIT_PAGE =
  window.location.origin + '/security-monitoring.pdf';
export const QUANTSTAMP_AUDIT_PAGE =
  window.location.origin + '/quantstamp-sc-audit.pdf';
export const FAQ_PAGE = '/faq';
export const DOCS_LINK = 'https://docs.retrobridge.io/';

export enum Routes {
  DAPP_EXPLORER_PAGE = 'DAPP_EXPLORER_PAGE',
  HOME_PAGE = 'HOME_PAGE',
  EXPLORER_PAGE = 'EXPLORER_PAGE',
  PROGRESS_PAGE = 'PROGRESS_PAGE',
  ADVANCED_PROGRESS_PAGE = 'ADVANCED_PROGRESS_PAGE',
  SEND_PAGE = 'SEND_PAGE',
  PRIVACY_POLICY_PAGE = 'PRIVACY_POLICY_PAGE',
  FAQ_PAGE = 'FAQ_PAGE',
  LANDING_PAGE = 'LANDING_PAGE',
  DEV_API_PAGE = 'DEV_API_PAGE',

  QUESTS_PAGE = 'QUESTS_PAGE',
  QUESTS_PAGE_PHASES = 'QUESTS_PAGE_PHASES',
  QUESTS_PAGE_ADVENTURES = 'QUESTS_PAGE_ADVENTURES',
  QUESTS_PAGE_ACTIVITIES = 'QUESTS_PAGE_ACTIVITIES',
  QUESTS_PAGE_MARKET = 'QUESTS_PAGE_MARKET',

  AUDIT_PAGE = 'AUDIT_PAGE',
  MONITORING_AUDIT_PAGE = 'MONITORING_AUDIT_PAGE',
  QUANTSTAMP_AUDIT_PAGE = 'QUANTSTAMP_AUDIT_PAGE',

  TESTNET_SEND_PAGE = 'TESTNET_SEND_PAGE',
  CHATBOT = 'CHATBOT',
}

const localRoutes: Record<Routes, string> = {
  [Routes.DAPP_EXPLORER_PAGE]: '/dapp-explorer',
  [Routes.HOME_PAGE]: '/',
  [Routes.EXPLORER_PAGE]: '/explorer',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.ADVANCED_PROGRESS_PAGE]: '/progress/advanced',
  [Routes.PRIVACY_POLICY_PAGE]: '/terms-conditions',
  [Routes.FAQ_PAGE]: '/faq',
  [Routes.SEND_PAGE]: '/send',
  [Routes.LANDING_PAGE]: '/',
  [Routes.DEV_API_PAGE]: '/for-developers',

  [Routes.QUESTS_PAGE]: '/quests',
  [Routes.QUESTS_PAGE_PHASES]: '/quests/phases',
  [Routes.QUESTS_PAGE_ADVENTURES]: '/quests/adventures',
  [Routes.QUESTS_PAGE_ACTIVITIES]: '/quests/activities',
  [Routes.QUESTS_PAGE_MARKET]: '/quests/market',

  [Routes.AUDIT_PAGE]: AUDIT_PAGE,
  [Routes.MONITORING_AUDIT_PAGE]: MONITORING_AUDIT_PAGE,
  [Routes.QUANTSTAMP_AUDIT_PAGE]: QUANTSTAMP_AUDIT_PAGE,

  [Routes.TESTNET_SEND_PAGE]: 'https://testnet.retrobridge.dev',
  [Routes.CHATBOT]: 'https://chat.retrobridge.dev',
};

const stagingRoutes: Record<Routes, string> = {
  [Routes.DAPP_EXPLORER_PAGE]: 'https://retrobridge.dev/dapp-explorer',
  [Routes.HOME_PAGE]: '/',
  [Routes.EXPLORER_PAGE]: 'https://explorer.retrobridge.dev',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.ADVANCED_PROGRESS_PAGE]: '/progress/advanced',
  [Routes.PRIVACY_POLICY_PAGE]:
    window.location.host === 'retrobridge.dev'
      ? '/terms-conditions'
      : 'https://retrobridge.dev/terms-conditions',
  [Routes.FAQ_PAGE]:
    window.location.host === 'retrobridge.dev'
      ? '/faq'
      : 'https://retrobridge.dev/faq',
  [Routes.SEND_PAGE]: 'https://app.retrobridge.dev',
  [Routes.LANDING_PAGE]: 'https://retrobridge.dev',
  [Routes.DEV_API_PAGE]: 'https://retrobridge.dev/for-developers',

  [Routes.QUESTS_PAGE]: 'https://retrobridge.dev/quests',
  [Routes.QUESTS_PAGE_PHASES]: 'https://retrobridge.dev/quests/phases',
  [Routes.QUESTS_PAGE_ADVENTURES]: 'https://retrobridge.dev/quests/adventures',
  [Routes.QUESTS_PAGE_ACTIVITIES]: 'https://retrobridge.dev/quests/activities',
  [Routes.QUESTS_PAGE_MARKET]: 'https://retrobridge.dev/quests/market',

  [Routes.AUDIT_PAGE]: 'https://retrobridge.dev/audit.pdf',
  [Routes.MONITORING_AUDIT_PAGE]:
    'https://retrobridge.dev/security-monitoring.pdf',
  [Routes.QUANTSTAMP_AUDIT_PAGE]:
    'https://retrobridge.dev/quantstamp-sc-audit.pdf',

  [Routes.TESTNET_SEND_PAGE]: 'https://testnet.retrobridge.dev',
  [Routes.CHATBOT]: 'https://chat.retrobridge.dev',
};

const productionRoutes: Record<Routes, string> = {
  [Routes.DAPP_EXPLORER_PAGE]: 'https://retrobridge.io/dapp-explorer',
  [Routes.HOME_PAGE]: '/',
  [Routes.EXPLORER_PAGE]: 'https://explorer.retrobridge.io',
  [Routes.PROGRESS_PAGE]: '/progress',
  [Routes.ADVANCED_PROGRESS_PAGE]: '/progress/advanced',
  [Routes.PRIVACY_POLICY_PAGE]:
    window.location.host === 'retrobridge.io'
      ? '/terms-conditions'
      : 'https://retrobridge.io/terms-conditions',
  [Routes.FAQ_PAGE]:
    window.location.host === 'retrobridge.io'
      ? '/faq'
      : 'https://retrobridge.io/faq',
  [Routes.SEND_PAGE]: 'https://app.retrobridge.io',
  [Routes.LANDING_PAGE]: 'https://retrobridge.io',
  [Routes.DEV_API_PAGE]: 'https://retrobridge.io/for-developers',

  [Routes.QUESTS_PAGE]: 'https://retrobridge.io/quests',
  [Routes.QUESTS_PAGE_PHASES]: 'https://retrobridge.io/quests/phases',
  [Routes.QUESTS_PAGE_ADVENTURES]: 'https://retrobridge.io/quests/adventures',
  [Routes.QUESTS_PAGE_ACTIVITIES]: 'https://retrobridge.io/quests/activities',
  [Routes.QUESTS_PAGE_MARKET]: 'https://retrobridge.io/quests/market',

  [Routes.AUDIT_PAGE]: 'https://retrobridge.io/audit.pdf',
  [Routes.MONITORING_AUDIT_PAGE]:
    'https://retrobridge.io/security-monitoring.pdf',
  [Routes.QUANTSTAMP_AUDIT_PAGE]:
    'https://retrobridge.io/quantstamp-sc-audit.pdf',

  [Routes.TESTNET_SEND_PAGE]: 'https://testnet.retrobridge.io',
  [Routes.CHATBOT]: 'https://chat.retrobridge.dev',
};

const routes: Record<STAGES, Record<Routes, string>> = {
  development: localRoutes,
  production: productionRoutes,
  staging: stagingRoutes,
};
export const ROUTES = IS_ONE_DOMAIN ? routes['development'] : routes[stage];

import { SVGAttributes } from 'react';

export function RetroGPTIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="27"
      height="18"
      viewBox="0 0 27 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4885 3.68237C14.2453 3.56675 14.825 2.91305 14.825 2.12395C14.825 1.25322 14.1192 0.547363 13.2485 0.547363C12.3777 0.547363 11.6719 1.25322 11.6719 2.12395C11.6719 2.87715 12.2001 3.507 12.9062 3.66329V4.52233C12.4338 4.6116 12.0254 4.88221 11.7563 5.25908C5.372 5.96691 0.416016 11.1563 0.416016 17.4528H2.21728C2.21728 11.6245 7.15679 6.89976 13.25 6.89976C19.3432 6.89976 24.2827 11.6245 24.2827 17.4528H26.084C26.084 11.1552 21.1264 5.96521 14.7406 5.25873C14.4522 4.8551 14.004 4.57342 13.4885 4.50599V3.68237ZM19.3741 14.0741C19.3741 15.0862 18.5536 15.9067 17.5415 15.9067C16.5294 15.9067 15.709 15.0862 15.709 14.0741C15.709 13.062 16.5294 12.2416 17.5415 12.2416C18.5536 12.2416 19.3741 13.062 19.3741 14.0741ZM8.85793 15.9067C9.87001 15.9067 10.6905 15.0862 10.6905 14.0741C10.6905 13.062 9.87001 12.2416 8.85793 12.2416C7.84585 12.2416 7.02539 13.062 7.02539 14.0741C7.02539 15.0862 7.84585 15.9067 8.85793 15.9067Z"
        fill={props.fill || 'url(#paint0_linear_19301_14414)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_19301_14414"
          x1="3"
          y1="11.5001"
          x2="26"
          y2="17.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B19EFF" />
          <stop offset="1" stopColor="#E8A8FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function RetroBridgeLogoGradient(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="22"
      viewBox="0 0 28 14"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.47022C7.74848 2.47022 2.68064 7.31773 2.68064 13.2974H0.832581C0.832581 6.34145 6.72783 0.702515 14 0.702515C21.2721 0.702515 27.1674 6.34145 27.1674 13.2974H25.3193C25.3193 7.31773 20.2515 2.47022 14 2.47022Z"
        fill={props.fill || 'url(#paint0_linear_19301_14414)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_19301_14414"
          x1="3"
          y1="11.5001"
          x2="26"
          y2="17.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B19EFF" />
          <stop offset="1" stopColor="#E8A8FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

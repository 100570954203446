import { useBitcoinWallet } from '@/bitcoin/hooks/useBitcoinWallet';
import { OptionsProps } from '..';

export function BitcoinOptions({ onOpenChange }: OptionsProps) {
  const { wallets } = useBitcoinWallet();

  // Extract Binance and OKX
  const binance = wallets.find(w => w.name === 'Binance Wallet');
  const okx = wallets.find(w => w.name === 'OKX Wallet');

  // Remove Binance and OKX from the list
  const otherWallets = wallets.filter(
    w => w.name !== 'Binance Wallet' && w.name !== 'OKX Wallet'
  );

  // Build final list with Binance at index 1 and OKX at index 2
  const finalWallets = [...otherWallets];

  if (binance) finalWallets.splice(0, 0, binance);
  if (okx) finalWallets.splice(1, 0, okx);

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Bitcoin wallets</div>
      <div className="connect-wallet--group-grid">
        {finalWallets.map((w, i) => (
          <button
            key={w.name}
            onClick={async () => {
              await w.connect();
              onOpenChange(false);
            }}
            className="connect-wallet--group-item"
          >
            <img
              src={w.icon}
              style={{ height: '24px', width: '24px' }}
              alt=""
            />
            {w.name}
          </button>
        ))}
      </div>
    </div>
  );
}

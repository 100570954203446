import { INetwork } from '../types/apiTypes';

type ILocalNetwork = Pick<
  INetwork,
  'id' | 'name' | 'chainId' | 'network_image_url' | 'category' | 'tag'
> & {
  order_on_main_page?: number;
  is_coming_soon?: boolean;
  is_testnet?: boolean;

  is_new_destination?: boolean;
  new_destination_bg?: string;
  is_latest_new_destination?: boolean;
  gradient?: string;
  task_bg?: string;
  dailyTaskImg?: string;
  capsuleImg?: string;
  capsuleWithBg?: string;
};

export const mainnetNetworks: ILocalNetwork[] = [
  {
    id: 'e8a1ff3d-0391-4214-9f0e-e6b691c9a11d',
    name: 'Ethereum',
    chainId: '1',
    network_image_url: '/static/networks/ethereum.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 0,
    task_bg: require('@/Assets/images/tasks/plain-bg/ethereum.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/ethereum.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/ethereum.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/ethereum.png'),
  },
  {
    id: '7f16e773-ac07-447f-9af0-f3b7f244d24b',
    name: 'Polygon',
    chainId: '137',
    network_image_url: '/static/networks/polygon.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 1,
    task_bg: require('@/Assets/images/tasks/plain-bg/polygon.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/polygon.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/polygon.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/polygon.png'),
  },
  {
    id: '9da2c35c-7b75-450b-97a1-3191a71f6c63',
    name: 'Arbitrum One',
    chainId: '42161',
    network_image_url: '/static/networks/arbitrum.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 3,
    task_bg: require('@/Assets/images/tasks/plain-bg/arb-one.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/arbitrum-one.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/arbitrum.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/arbitrumone.png'),
  },
  {
    id: 'f51b2b7c-d09c-4502-b72b-86f069a74d92',
    name: 'Optimism',
    chainId: '10',
    network_image_url: '/static/networks/optimism.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 9,
    task_bg: require('@/Assets/images/tasks/plain-bg/optimism.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/optimism.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/optimism.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/optimism.png'),
  },
  {
    id: '0a2541ef-83ac-48f1-af59-ebf1d438a61d',
    name: 'zkSync Era',
    chainId: '324',
    network_image_url: '/static/networks/zkSyncEra.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 5,
    task_bg: require('@/Assets/images/tasks/plain-bg/zksync.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/zksync.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/zksync.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/zksyncera.png'),
  },
  {
    id: '7142f22b-221c-4d7d-b636-d5f23638b753',
    name: 'Starknet',
    chainId: '0x534e5f4d41494e',
    network_image_url: '/static/networks/starkNet.svg',
    category: 'L2 Non-EVM Networks',
    tag: 'L2 Non-EVM',
    order_on_main_page: 7,
    task_bg: require('@/Assets/images/tasks/plain-bg/starknet.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/starknet.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/starknet.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/starknet.png'),
  },
  {
    id: 'fa45ace7-d6a7-460f-b773-1ba21ff3bb93',
    name: 'Fuel',
    chainId: '9889',
    network_image_url: '/static/networks/fuel.png',
    category: 'L1 Non-EVM Networks',
    tag: 'L2 Non-EVM',
    order_on_main_page: 8,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/FuelBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/fuel.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/fuel.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/fuel.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/fuel.png'),
  },
  {
    id: '26ef6cb3-f7d8-42fe-a9e4-f7bcfbddb5c3',
    name: 'Base',
    chainId: '8453',
    network_image_url: '/static/networks/base.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 10,
    task_bg: require('@/Assets/images/tasks/plain-bg/base.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/base.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/base.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/base.png'),
  },
  {
    id: '2d7e8bbc-193e-4ed5-a512-7cd49c1d27d9',
    name: 'Linea',
    chainId: '59144',
    network_image_url: '/static/networks/linea.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 6,
    task_bg: require('@/Assets/images/tasks/plain-bg/linea.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/linea.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/linea.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/linea.png'),
  },
  {
    id: 'e6fc724a-6cff-4510-8276-ffcf21f97b03',
    name: 'BNB Chain',
    chainId: '56',
    network_image_url: '/static/networks/binance.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 2,
    task_bg: require('@/Assets/images/tasks/plain-bg/bnb.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/bnb.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/bnb.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/bnb.png'),
  },
  {
    id: '226f910f-2108-492d-9c5d-63dc75c6796b',
    name: 'Avalanche',
    chainId: '43114',
    network_image_url: '/static/networks/avalanche.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 9,
    task_bg: require('@/Assets/images/tasks/plain-bg/avalanche.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/avalanche.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/avalanche.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/avalanche.png'),
  },
  {
    id: '51748ee9-43dd-49f7-9c4c-7410c6e9c5ba',
    name: 'Arbitrum Nova',
    chainId: '42170',
    network_image_url: '/static/networks/arbitrumNova.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 4,
    task_bg: require('@/Assets/images/tasks/plain-bg/arb-nova.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/arbitrum-nova.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/arb-nova.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/arbitrumnova.png'),
  },
  {
    id: 'b7db1b45-6e3c-4623-b6d7-af31e4bad8eb',
    name: 'Polygon zkEVM',
    chainId: '1101',
    network_image_url: '/static/networks/polygonZkEVM.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 6,
    task_bg: require('@/Assets/images/tasks/plain-bg/polygon-zkevm.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/zkevm.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/zkevm.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/polygonzkevm.png'),
  },
  {
    id: 'f143deba-ca33-4f9d-be86-c9dfde17dbb9',
    name: 'Scroll',
    chainId: '534352',
    network_image_url: '/static/networks/scroll_network.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 11,
    task_bg: require('@/Assets/images/tasks/plain-bg/scroll.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/scroll.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/scroll.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/scroll.png'),
  },
  {
    id: 'c2ac0244-3e25-42bb-9677-cd8c3746273b',
    name: 'Zora',
    chainId: '7777777',
    network_image_url: '/static/networks/zora.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 12,
    task_bg: require('@/Assets/images/tasks/plain-bg/zora.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/zora.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/zora.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/zora.png'),
  },
  {
    id: '2de8dc80-f117-4dc9-9b95-19fb3f60b2d8',
    name: 'Manta Pacific',
    chainId: '169',
    network_image_url: '/static/networks/mantaPacific.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 13,
    task_bg: require('@/Assets/images/tasks/plain-bg/manta.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/manta.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/manta.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/mantapacific.png'),
  },
  {
    id: '15ca069c-cc8e-45a9-8991-03cf84ea9de5',
    name: 'Mode',
    chainId: '34443',
    network_image_url: '/static/networks/mode.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 14,
    task_bg: require('@/Assets/images/tasks/plain-bg/mode.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/mode.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/mode.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/mode.png'),
  },
  {
    id: '2cb23b31-d5a5-4b21-8f77-7b65c4561b92',
    name: 'Kroma',
    chainId: '255',
    network_image_url: '/static/networks/kroma.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 15,
    task_bg: require('@/Assets/images/tasks/plain-bg/kroma.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/kroma.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/kroma.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/kroma.png'),
  },
  {
    id: 'd2da2429-21f6-4f3c-9979-2f5f967654b4',
    name: 'Mantle',
    chainId: '5000',
    network_image_url: '/static/networks/mantle.svg',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 14,
    task_bg: require('@/Assets/images/tasks/plain-bg/mantle.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/mantle.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/mantle.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/mantle.png'),
  },
  {
    id: '531a1820-1852-4b22-8662-1ad9eb1a4443',
    name: 'Zeta Chain',
    chainId: '7000',
    network_image_url: '/static/networks/zeta_chain.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 13,
    task_bg: require('@/Assets/images/tasks/plain-bg/zeta.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/zeta.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/zetachain.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/zetachain.png'),
  },
  {
    id: '2f4af8d9-81fd-4f4a-b428-dfeca99eb160',
    name: 'Blast',
    chainId: '81457',
    network_image_url: '/static/networks/blast.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 15,
    task_bg: require('@/Assets/images/tasks/plain-bg/blast.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/blast.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/blast.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/blast.png'),
  },
  {
    id: '5a2ac0fc-41a3-4fe2-a3cc-8ff1e808d96b',
    name: 'zkLink Nova',
    chainId: '810180',
    network_image_url: '/static/networks/zkLinkNova.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 16,
    task_bg: require('@/Assets/images/tasks/plain-bg/zklink.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/zklink.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/zklink.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/zklinknova.png'),
  },
  {
    id: 'e3e9b7bf-3d7f-4c15-8188-eef9dbc97c42',
    name: 'Rari Chain',
    chainId: '1380012617',
    network_image_url: '/static/networks/rari_chain.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 19,
    task_bg: require('@/Assets/images/tasks/plain-bg/rari.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/rari.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/rari.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/rarichain.png'),
  },
  {
    id: '19c9a829-f2f0-4974-8ef9-7f6d8f126f1a',
    name: 'X Layer',
    chainId: '196',
    network_image_url: '/static/networks/x_layer.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 20,
    task_bg: require('@/Assets/images/tasks/plain-bg/xlayer.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/xlayer.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/xlayer.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/xlayer.png'),
  },
  {
    id: 'd93e91e0-a457-482a-b7a4-22f91c9cc656',
    name: 'Taiko',
    chainId: '167000',
    network_image_url: '/static/networks/taiko.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 17,
    task_bg: require('@/Assets/images/tasks/plain-bg/taiko.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/taiko.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/taiko.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/taiko.png'),
  },
  {
    id: '3c213de8-7564-4f5c-a2ed-e346e817e1c0',
    name: 'TON',
    chainId: '0',
    network_image_url: '/static/networks/ton.svg',
    category: 'Innovative Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 23,
    is_new_destination: true,
    is_latest_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/tonBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/ton.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/ton.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/ton.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/ton.png'),
  },
  {
    id: 'b666ac9a-ecc7-4531-a403-746f5cf9ca7d',
    name: 'opBNB',
    chainId: '204',
    network_image_url: '/static/networks/binance.svg',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 21,
    task_bg: require('@/Assets/images/tasks/plain-bg/bnb.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/bnb.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/bnb.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/bnb.png'),
  },
  {
    id: 'b08b886e-b215-431a-9192-ad3b26c23122',
    name: 'Mint Chain',
    chainId: '185',
    network_image_url: '/static/networks/mint_chain.png',
    category: 'Innovative Networks',
    tag: 'L2 EVM',
    order_on_main_page: 22,
    is_new_destination: false,
    new_destination_bg: require('@/Assets/images/networks/mintBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/mint.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/mint.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/mint.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/mintchain.png'),
  },
  {
    id: 'fca5bf9d-fc16-467f-9d59-03e41e79d8a5',
    name: 'Merlin',
    chainId: '4200',
    network_image_url: '/static/networks/merlin.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 24,
    is_new_destination: false,
    is_latest_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/merlinBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/merlin.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/merlin.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/merlin.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/merlin.png'),
  },
  {
    id: 'a9cf1f67-340d-4caf-8801-ca65e56751c0',
    name: 'B² Network',
    chainId: '223',
    network_image_url: '/static/networks/bsquared.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 24,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/B2BannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/b2.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/b2.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/b2.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/bsquare.png'),
  },
  {
    id: '15839712-445a-4a67-8b55-71c235e4fd90',
    name: 'BOB',
    chainId: '60808',
    network_image_url: '/static/networks/bob.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 25,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/BOBBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/bob.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/bob.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/bob.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/bob.png'),
  },
  {
    id: '0134dbf9-0390-4aca-98a7-db9912c10a10',
    name: 'Bitlayer',
    chainId: '200901',
    network_image_url: '/static/networks/bitlayer.png',
    category: 'L2 BTC Networks',
    tag: 'L2 BTC',
    order_on_main_page: 26,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/BitlayerBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/bitlayer.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/bitlayer.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/bitlayer.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/bitlayer.png'),
  },
  {
    id: 'db99e982-dc13-4302-bc16-8a882e223d25',
    name: 'Metis',
    chainId: '1088',
    network_image_url: '/static/networks/metis.png',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 27,
    task_bg: require('@/Assets/images/tasks/plain-bg/metis.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/metis.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/metis.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/metis.png'),
  },
  {
    id: 'b9881297-f8f7-4a92-a0de-5f474cb8e199',
    name: 'Solana',
    chainId: '900',
    network_image_url:
      window.location.origin + require('@/Assets/Icons/networks/solana.png'),

    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 28,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/SolanaBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/solana.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/solana.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/solana.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/solana.png'),
  },
  {
    id: 'ac543856-8112-498d-ae46-a328072c6d34',
    name: 'TRON',
    chainId: '1000',
    network_image_url: '/static/networks/tron.png',
    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 29,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/TronBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/tron.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/tron.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/tron.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/tron.png'),
  },
  {
    id: 'a23cd70f-6eb2-431f-9ce6-5d65e4b22263',
    name: 'Zircuit',
    chainId: '48900',
    network_image_url: '/static/networks/zircuit.png',
    category: 'L2 EVM Networks',
    tag: 'Innovative Networks',
    order_on_main_page: 30,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/zircuitBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/zircuit.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/zircuit.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/zircuit.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/zircuit.png'),
  },
  {
    id: '26904570-3a50-457f-b849-83131bb25322',
    name: 'Gravity',
    chainId: '1625',
    network_image_url: '/static/networks/gravity.png',
    category: 'L1 EVM Networks',
    order_on_main_page: 31,
    tag: 'L1 EVM',
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/gravityBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/gravity.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/gravity.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/gravity.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/gravity.png'),
  },
  {
    id: '2b43f703-7f37-425b-b9f6-9e783104210e',
    name: 'ApeChain',
    chainId: '33139',
    network_image_url: '/static/networks/ape_chain.png',
    category: 'L2 EVM Networks',
    tag: 'Innovative Networks',
    order_on_main_page: 32,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/ApeBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/ape.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/ape.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/ape.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/apechain.png'),
  },
  // {
  //   id: 'bb0b85cd-77ca-4fd7-8cf0-f915ecde5ff8',
  //   name: 'World Chain',
  //   chainId: '480',
  //   network_image_url: '/static/networks/world_chain.png',
  //   category: 'L2 EVM Networks',
  //   tag: 'L2 EVM',
  //   order_on_main_page: 33,
  //   is_new_destination: true,
  //   task_bg: require('@/Assets/images/tasks/plain-bg/world.png'),
  //   dailyTaskImg: require('@/Assets/images/tasks/coins/world.png'),
  // },
  {
    id: '23caeb63-07d6-4c7e-935a-6884c2202342',
    name: 'Morph',
    chainId: '2818',
    network_image_url: '/static/networks/morph.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 34,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/MorphBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/morph.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/morph.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/morph.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/morph.png'),
  },
  {
    id: 'aa54c16c-3bbe-4aa3-a4d4-6766eb1c9508',
    name: 'Bitcoin',
    chainId: '101113',
    network_image_url: '/static/networks/bitcoin.svg',
    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 35,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/BitcoinBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/bitcoin.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/bitcoin.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/bitcoin.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/bitcoin.png'),
  },
  {
    id: '2ab4e58c-4772-400f-b0d7-9997f7405d49',
    name: 'Sui',
    chainId: '101',
    network_image_url:
      window.location.origin +
      require('@/Assets/Icons/networks/sui.svg').default,
    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    order_on_main_page: 36,
    is_new_destination: true,
    new_destination_bg: require('@/Assets/images/networks/SuiBannerBg.png'),
    task_bg: require('@/Assets/images/tasks/plain-bg/sui.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/sui.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/sui.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/sui.png'),
  },
  {
    id: '6506fc94-67c2-4ce3-835f-8531d02359f6',
    name: 'Eclipse',
    chainId: '902',
    network_image_url: '/static/networks/eclipse.png',
    category: 'L2 Non-EVM Networks',
    tag: 'L2 Non-EVM',
    order_on_main_page: 37,
    task_bg: require('@/Assets/images/tasks/plain-bg/eclipse.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/eclipse.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/eclipse.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/eclipse.png'),
  },
  {
    id: '83359327-16e4-4c4e-97d7-ff93c1dd23b3',
    name: 'Sonic',
    chainId: '146',
    network_image_url: '/static/networks/sonic.png',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 38,
    task_bg: require('@/Assets/images/tasks/plain-bg/sonic.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/sonic.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/sonic.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/sonic.png'),
  },
  {
    id: 'b9e2bb52-ecfc-4ab9-864d-15081497db3b',
    name: 'Abstract',
    chainId: '2741',
    network_image_url: '/static/networks/abstract.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 39,
    task_bg: require('@/Assets/images/tasks/plain-bg/abstract.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/abstract.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/abstract.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/abstract.png'),
  },
  {
    id: '02d38a7c-5017-4461-a6a3-dbc3c60a007d',
    name: 'Berachain',
    chainId: '80094',
    network_image_url: '/static/networks/bera.svg',
    category: 'L1 EVM Networks',
    tag: 'L1 EVM',
    order_on_main_page: 40,
    task_bg: require('@/Assets/images/tasks/plain-bg/bera.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/bera.png'),
    capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/berachain.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/berachain.png'),
  },
  {
    id: '076392a5-f846-4015-adb3-2f06c8863877',
    name: 'Unichain',
    chainId: '130',
    network_image_url: '/static/networks/unichain.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 41,
    task_bg: require('@/Assets/images/tasks/plain-bg/unichain.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/unichain.png'),
    /*capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/unichain.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/unichain.png'), */
  },
  {
    id: '2e1be998-137b-4619-bf05-f33e5395f58e',
    name: 'Soneium',
    chainId: '1868',
    network_image_url: '/static/networks/soneium.png',
    category: 'L2 EVM Networks',
    tag: 'L2 EVM',
    order_on_main_page: 42,
    task_bg: require('@/Assets/images/tasks/plain-bg/soneium.png'),
    dailyTaskImg: require('@/Assets/images/tasks/coins/soneium.png'),
    /*capsuleWithBg: require('@/Assets/images/tasks/capsules/bg/soneium.png'),
    capsuleImg: require('@/Assets/images/tasks/capsules/transparent/soneium.png'), */
  },
];

export const testnetNetworks: ILocalNetwork[] = [
  {
    id: 'c7d59050-6f3e-4b9c-a793-852c89859553',
    name: 'Goerli Testnet',
    chainId: '5',
    network_image_url: '/static/networks/ethereum.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: '839c3c7f-9d9a-4783-b1f1-02e258fe736b',
    name: 'Mumbai Testnet',
    chainId: '80001',
    network_image_url: '/static/networks/polygon.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: '0e6268d5-6757-47bf-bd04-217ff8d6974e',
    name: 'BNB Smart Chain Testnet',
    chainId: '97',
    network_image_url: '/static/networks/binance.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: '588dc52b-1b9e-47e8-b4c8-bcdc9a5e85ac',
    name: 'Sepolia Testnet',
    chainId: '11155111',
    network_image_url: '/static/networks/ethereum.svg',
    category: 'L1 EVM Networks',
    is_testnet: true,
  },
  {
    id: 'e8599c5c-3176-421f-8e2a-77306bd30fff',
    name: 'Fantom Testnet',
    chainId: '4002',
    network_image_url: '/static/networks/fantom.webp',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '44a7bd7c-168a-42aa-8156-b5e27138ebb5',
    name: 'Scroll Sepolia Testnet',
    chainId: '534351',
    network_image_url: '/static/networks/scroll_network.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '2d7e8bbc-193e-4ed5-a512-7cd49c1d27d9',
    name: 'Linea Testnet',
    chainId: '59140',
    network_image_url: '/static/networks/linea.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '355ff90f-ba61-41fa-900a-67e54d00a2f7',
    name: 'zkSync Era Testnet',
    chainId: '280',
    network_image_url: '/static/networks/zkSyncEra.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: 'e2c334bf-e874-432d-a227-291d0784a32e',
    name: 'Starknet Testnet',
    chainId: '0x534e5f474f45524c49',
    network_image_url: '/static/networks/starkNet.svg',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '30a9afc7-d927-4e17-acf2-16544ba8050d',
    name: 'Zora Testnet',
    chainId: '999999999',
    network_image_url: '/static/networks/zora.png',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: '38e020c4-7e6e-4c3f-9a60-8d98612a706a',
    name: 'Manta Pacific Testnet',
    chainId: '3441005',
    network_image_url: '/static/networks/mantaPacific.png',
    category: 'L2 EVM Networks',
    is_testnet: true,
  },
  {
    id: 'ba9a69f3-5d24-4cc9-bc90-7ea0d88e8c5e',
    name: 'Rari Chain Testnet',
    chainId: '1918988905',
    network_image_url: '/static/networks/rari_chain.png',
    category: 'Innovative Networks',
    is_testnet: true,
  },
  {
    id: 'e27a839f-a668-4b88-82db-04b7d95cbea3',
    name: 'TON Testnet',
    chainId: '0',
    network_image_url: '/static/networks/ton.svg',
    category: 'Innovative Networks',
    is_testnet: true,

    new_destination_bg: require('@/Assets/images/networks/tonBannerBg.png'),
  },
  {
    id: '1aedcb46-1a3c-48b0-82c0-fb019d6eba89',
    name: 'Solana Testnet',
    chainId: '901',
    network_image_url: '/static/networks/solana.png',
    category: 'L1 Non-EVM Networks',
    tag: 'L1 Non-EVM',
    is_testnet: true,
  },
];
